import { SiteDto } from '@appTypes/models/site.dto';
import { UserRole, UserSimpleDto } from '@appTypes/models/user.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { useDataManipulation, useRedirect, useFilters, useCurrentUserInfo } from '@hooks';
import { EditRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import CONFIG from '@settings/config';
import { dictionary } from '@settings/dictionary';
import { ColumnDef } from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';
import { getEcdPermissionTitle } from '@utils/data/EcdPermissionTitles';
import { normaliseString } from '@utils/utils';
import { palette } from 'styles/palette';

type UsersGridCellProps = {
  id: number;
  isActive: boolean;
  user: UserSimpleDto;
  onEditEcdPermission: (user: UserSimpleDto) => void;
  isSuperAdmin: boolean;
};

const getRole = (roleName: string, isActive: boolean) => {
  if (!isActive) {
    return dictionary.statusUserDeactivated;
  }

  if (roleName === UserRole.ADMIN) {
    return dictionary.roleManager;
  }

  if (roleName === UserRole.USER) {
    return dictionary.roleTechnician;
  }

  return roleName;
};

const CellWithEditEcdPermissionButton = ({
  id,
  isActive,
  user,
  onEditEcdPermission,
  isSuperAdmin,
}: UsersGridCellProps) => {
  const redirect = useRedirect();

  return (
    <CenterX color={isActive ? undefined : palette.neutral.main} sx={{ gap: '6px' }}>
      <span>{getEcdPermissionTitle(user.device_permission)}</span>
      {isSuperAdmin && (
        <Tooltip
          trigger={
            <IconButton
              onClick={() => onEditEcdPermission(user)}
              data-cy={`edit-permission-button-${id}`}
              disabled={!isActive}
            >
              <EditRounded sx={{ color: isActive ? palette.primary.main : palette.neutral.main }} />
            </IconButton>
          }
          placement="top"
        >
          {!isActive ? dictionary.tooltipCannotEditEcdPermissionsDeactivatedUser : ''}
        </Tooltip>
      )}
      <Button
        variant="outlined"
        sx={{ marginLeft: 'auto' }}
        onClick={() => redirect((paths) => paths.configurationUser(id))}
      >
        {dictionary.buttonUserDetail}
      </Button>
    </CenterX>
  );
};

const getColumns = (
  onEditEcdPermission: (user: UserSimpleDto) => void,
  isSuperAdmin: boolean,
): ColumnDef<UserSimpleDto>[] => [
  {
    header: dictionary.headerUser,
    accessorKey: 'last_name',
    enableSorting: false,
    cell: ({ row }) => (
      <div
        style={{
          flex: '0 0 auto',
          minWidth: '150px',
          maxWidth: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: row.original.is_active ? undefined : palette.neutral.main,
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          {createFullName(row.original.first_name, row.original.last_name)}
        </div>
        <div>{row.original.email}</div>
      </div>
    ),
  },
  {
    header: dictionary.headerRole,
    accessorKey: 'role',
    enableSorting: false,
    cell: ({ row }) => (
      <div
        style={{
          flex: '0 0 auto',
          color: row.original.is_active ? undefined : palette.error.main,
        }}
      >
        {getRole(row.original.role.name, row.original.is_active)}
      </div>
    ),
  },
  {
    header: dictionary.headerEcdPermissions,
    accessorKey: 'ecd_permissions',
    enableSorting: false,
    cell: ({ row }) => (
      <div
        style={{
          flex: '0 0 auto',
        }}
      >
        <CellWithEditEcdPermissionButton
          id={row.original.id}
          user={row.original}
          isActive={row.original.is_active}
          onEditEcdPermission={onEditEcdPermission}
          isSuperAdmin={isSuperAdmin}
        />
      </div>
    ),
  },
];

interface UserListProps {
  siteData?: SiteDto;
  onAddRelatedUser: () => void;
  onEditEcdPermission: (user: UserSimpleDto) => void;
}

const UserList = ({ siteData, onAddRelatedUser, onEditEcdPermission }: UserListProps) => {
  const { isSuperAdmin, isUserOnly } = useCurrentUserInfo();

  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<UserSimpleDto>({
    data: siteData?.users || [],
    searchString,
    sorting,
    pagination,
    getSearchableString: (user) =>
      normaliseString([user.first_name, user.last_name, user.email].join(' ')),
    getSortableValue: (user, sortId) => user[sortId as keyof UserSimpleDto],
    enableSorting: false,
  });

  return (
    <>
      <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedUser} disabled={isUserOnly}>
        {dictionary.buttonAddRelatedUser}
      </ButtonAdd>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<UserSimpleDto>
        columns={getColumns(onEditEcdPermission, isSuperAdmin)}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default UserList;
