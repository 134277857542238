import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { SelectOptions, useGetSitesAllForSelectQuery } from '@apiRtk/sites';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

const renderSiteSelectOrganizationItem = (option: SelectOptions[0]) => {
  const { name } = option;

  return (
    <CenterX gap={1} justifyItems="space-between">
      <Box>
        <Typography>{name || '-'}</Typography>
      </Box>
    </CenterX>
  );
};

type SiteSelectOrganizationProps = {
  organizationId: number;
  name: string;
  control: Control<any>;
  setValue: any;
  required?: boolean;
};

const SiteSelectOrganization = ({
  organizationId,
  control,
  setValue,
  required,
  name,
}: SiteSelectOrganizationProps) => {
  const { data: sitesData, isLoading } = useGetSitesAllForSelectQuery();
  const { data: organizationData, isLoading: isOrganizationLoading } =
    useGetOrganizationQuery(organizationId);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label="Search site" />
  );

  if (isLoading || isOrganizationLoading || !sitesData || !organizationData) {
    return <Spinner size={15} />;
  }

  const getFilteredOptions = (
    sites: SelectOptions,
    organization: OrganizationDto,
  ): SelectOptions => {
    const sitesToFilter = organization.sites;
    return sites.filter(
      (site) => !sitesToFilter.some((filteredSite) => filteredSite.id === site.id),
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={{ required }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={getFilteredOptions(sitesData, organizationData)}
          onChange={(_, val) => {
            setValue(name, val?.id || null, { shouldValidate: true });
          }}
          onBlur={() => {
            if (!field.value) {
              setValue(name, null, { shouldValidate: true });
            }
          }}
          value={sitesData.find((site) => site.id === field.value) || null}
          getOptionLabel={(option) => `${option.name}`}
          renderInput={(params) => renderInput(params)}
          renderOption={(props, siteData) => {
            const { key, ...rest } = props;
            return (
              <li key={key} {...rest}>
                {renderSiteSelectOrganizationItem(siteData)}
              </li>
            );
          }}
        />
      )}
    />
  );
};

export default SiteSelectOrganization;
