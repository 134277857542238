import { UserRole } from '@appTypes/models/user.dto';
import { dictionary } from '@settings/dictionary';
import { regex } from '@settings/regex';
import { z } from 'zod';

export const userFormSchema = z.object({
  organization_id: z.number({
    required_error: dictionary.validationOrganizationIdRequired,
    invalid_type_error: dictionary.validationOrganizationIdInvalidType,
  }),
  email: z
    .string({
      required_error: dictionary.validationEmailRequired,
      invalid_type_error: dictionary.validationEmailInvalidType,
    })
    .email({ message: dictionary.validationEmailInvalidType }),
  first_name: z
    .string({
      required_error: dictionary.validationFirstNameRequired,
      invalid_type_error: dictionary.validationFirstNameInvalidType,
    })
    .regex(regex.userFirstName, { message: dictionary.validationFirstNameInvalidCharacters })
    .min(2, { message: dictionary.validationFirstNameMinLength })
    .max(50, { message: dictionary.validationFirstNameMaxLength }),
  last_name: z
    .string({
      required_error: dictionary.validationLastNameRequired,
      invalid_type_error: dictionary.validationLastNameInvalidType,
    })
    .regex(regex.userLastName, { message: dictionary.validationLastNameInvalidCharacters })
    .min(2, { message: dictionary.validationLastNameMinLength })
    .max(50, { message: dictionary.validationLastNameMaxLength }),
  phone: z
    .string({
      required_error: dictionary.validationPhoneRequired,
      invalid_type_error: dictionary.validationPhoneInvalidType,
    })
    .regex(regex.phoneNumber, { message: dictionary.validationPhoneInvalidCharacters })
    .min(4, { message: dictionary.validationPhoneMinLength })
    .max(20, { message: dictionary.validationPhoneMaxLength }),
  role_name: z.nativeEnum(UserRole, {
    required_error: dictionary.validationRoleNameRequired,
    invalid_type_error: dictionary.validationRoleNameInvalidType,
  }),
});
