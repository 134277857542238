import { dictionary } from '@settings/dictionary';
import { regex } from '@settings/regex';
import { z } from 'zod';

const organizationFormSchema = z.object({
  name: z
    .string({
      required_error: dictionary.validationOrganizationNameRequired,
      invalid_type_error: dictionary.validationOrganizationNameInvalidType,
    })
    .regex(regex.organizationName, {
      message: dictionary.validationOrganizationNameInvalidCharacters,
    })
    .min(2, { message: dictionary.validationOrganizationNameMinLength })
    .max(50, { message: dictionary.validationOrganizationNameMaxLength }),
  manager_name: z
    .string({
      required_error: dictionary.validationManagerNameRequired,
      invalid_type_error: dictionary.validationManagerNameInvalidType,
    })
    .regex(regex.managerName, { message: dictionary.validationManagerNameInvalidCharacters })
    .min(4, { message: dictionary.validationManagerNameMinLength })
    .max(50, { message: dictionary.validationManagerNameMaxLength }),
  manager_email: z
    .string({
      required_error: dictionary.validationEmailRequired,
      invalid_type_error: dictionary.validationEmailInvalidType,
    })
    .email({ message: dictionary.validationEmailInvalidType }),
  manager_phone: z
    .string({
      required_error: dictionary.validationPhoneRequired,
      invalid_type_error: dictionary.validationPhoneInvalidType,
    })
    .regex(regex.phoneNumber, { message: dictionary.validationPhoneInvalidCharacters })
    .min(4, { message: dictionary.validationPhoneMinLength })
    .max(20, { message: dictionary.validationPhoneMaxLength }),
  street: z
    .string({
      required_error: dictionary.validationStreetRequired,
      invalid_type_error: dictionary.validationStreetInvalidType,
    })
    .regex(regex.street, { message: dictionary.validationStreetInvalidCharacters })
    .min(2, { message: dictionary.validationStreetMinLength })
    .max(50, { message: dictionary.validationStreetMaxLength }),
  city: z
    .string({
      required_error: dictionary.validationCityRequired,
      invalid_type_error: dictionary.validationCityInvalidType,
    })
    .regex(regex.city, {
      message: dictionary.validationCityInvalidCharacters,
    })
    .min(2, { message: dictionary.validationCityMinLength })
    .max(50, { message: dictionary.validationCityMaxLength }),
  postal_code: z
    .string({
      required_error: dictionary.validationPostalCodeRequired,
      invalid_type_error: dictionary.validationPostalCodeInvalidType,
    })
    .regex(regex.postalCode, { message: dictionary.validationPostalCodeInvalidCharacters })
    .min(4, { message: dictionary.validationPostalCodeMinLength })
    .max(10, { message: dictionary.validationPostalCodeMaxLength }),
  country: z
    .string({
      required_error: dictionary.validationCountryRequired,
      invalid_type_error: dictionary.validationCountryInvalidType,
    })
    .regex(regex.country, {
      message: dictionary.validationCountryInvalidCharacters,
    })
    .min(4, { message: dictionary.validationCountryMinLength })
    .max(30, { message: dictionary.validationCountryMaxLength }),
});

export default organizationFormSchema;
