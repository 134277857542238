import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { dictionary } from '@settings/dictionary';
import { paths } from 'paths';
import CreateOrganizationForm from './OrganizationForm/CreateOrganizationForm';

const CreateOrganization = () => (
  <>
    <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.ORGANIZATIONS }}>
      {dictionary.buttonBackToConfigurationList}
    </ButtonBack>
    <TitleBar my={4} title="Organization" titleVariant="h5" />
    <CreateOrganizationForm />
  </>
);

export default CreateOrganization;
