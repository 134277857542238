import { useAddSiteRelatedUserMutation } from '@apiRtk/sites';
import { EcdDevicesPermissions } from '@appTypes/models/site.dto';
import UserSelectSite from '@components/Form/UserSelectSite';
import { GapY } from '@components/LayoutUtils';
import FormModal from '@components/Modals/FormModal';
import { Select, MenuItem } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { dictionary } from '@settings/dictionary';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

const ecdDevicesPermissionsItems = [
  { value: EcdDevicesPermissions.VIEW, label: dictionary.ecdPermissionView },
  {
    value: EcdDevicesPermissions.BASIC_EDIT,
    label: dictionary.ecdPermissionBasicEdit,
  },
  {
    value: EcdDevicesPermissions.ADVANCED_EDIT,
    label: dictionary.ecdPermissionAdvancedEdit,
  },
];

type FormValues = {
  userId: Nullable<number>;
  permissions: EcdDevicesPermissions;
};

const defaultValues = {
  userId: null,
  permissions: EcdDevicesPermissions.VIEW,
};

export const UserRelationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [addUserToSite, { isLoading }] = useAddSiteRelatedUserMutation();
  const params = useParams();
  const siteId = parseInt(params.id!, 10);

  const { handleSubmit, control, setValue, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  const handleFormSubmit: SubmitHandler<FormValues> = async (fieldValues) => {
    const { userId, permissions } = fieldValues;

    if (!userId) {
      return;
    }

    try {
      await addUserToSite({
        id: siteId,
        user_id: userId,
        devices_permissions: permissions,
      }).unwrap();

      setOpen(false);
      reset();
      notificationService.success(dictionary.successUserAdded);
    } catch (err) {
      notificationService.error(dictionary.errorAddUserToSite);
    }
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={dictionary.dialogTitleAddRelatedUser}
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isLoading}
    >
      <UserSelectSite
        siteId={siteId}
        name="userId"
        control={control}
        setValue={setValue}
        required
      />

      <GapY size={2} />

      <Controller
        name="permissions"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            size="medium"
            label={dictionary.labelEcdDevicesPermissions}
            value={value || ''}
            fullWidth
            onChange={onChange}
            sx={{ minWidth: '400px' }}
          >
            {ecdDevicesPermissionsItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormModal>
  );
};
