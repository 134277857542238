import { ENDPOINT_WITH_FILTERS, FilterParamsCommonDto } from '@appTypes/models/common.dto';
import {
  CreateOrganizationDto,
  OrganizationDto,
  OrganizationResponse,
  OrganizationSimpleDto,
  OrganizationsDto,
  UpdateOrganizationDto,
} from '@appTypes/models/organization.dto';
import { createEndpointQueryForAllItems, createParams } from '@utils/utils';
import { coreApi } from './core';

type SelectOptions = {
  id: number;
  name: string;
}[];

export const organizationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<OrganizationDto, number>({
      query: (organizationId) => `organizations/${organizationId}`,
      providesTags: (result, error, organizationId) => [
        { type: 'Organization', id: organizationId },
      ],
    }),
    getOrganizations: builder.query<OrganizationResponse, FilterParamsCommonDto>({
      query: ({ ...params }) => `organizations?${createParams(params)}`,
      providesTags: ['Organizations'],
    }),
    getOrganizationsAll: builder.query<OrganizationSimpleDto[], void>({
      query: () => createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.ORGANIZATIONS),
    }),
    getOrganizationsAllForSelect: builder.query<SelectOptions, void>({
      query: () =>
        `${createEndpointQueryForAllItems(ENDPOINT_WITH_FILTERS.ORGANIZATIONS)}&order_by=name`,
      transformResponse: (response: OrganizationsDto) => {
        if (!response.organizations) return [];

        const organizations = [...response.organizations].map(({ id, name }) => ({
          id,
          name,
        }));

        return organizations;
      },
    }),
    createOrganization: builder.mutation<OrganizationDto, CreateOrganizationDto>({
      query: ({ ...payload }) => ({
        url: 'organizations',
        method: 'POST',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation<OrganizationDto, UpdateOrganizationDto>({
      query: ({ id, ...payload }) => ({
        url: `organizations/${id}`,
        method: 'PATCH',
        data: {
          ...payload,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Organization', id }, 'Organizations'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useGetOrganizationsAllForSelectQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} = organizationsApi;
