import React from 'react';
import { isControl } from '@components/Chart/utils/helpers';
import { Flex } from '@components/LayoutUtils';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { ReactComponent as IconInfo } from '@icons/info.svg';
import { Box, Typography, styled } from '@mui/material';
import { dictionary } from '@settings/dictionary';

export type SeriesItem = {
  name: string;
  label: string;
  color: string;
  yAxisIndex: number;
};

type ChartLegendProps = {
  series: SeriesItem[];
  hiddenSeries: string[];
  emptySeries: string[];
  setHiddenSeries: React.Dispatch<React.SetStateAction<string[]>>;
  onChange?: (hiddenSeries: string[]) => void;
};

const Column = styled('div')<{ side: 'left' | 'right' }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-grow: 1;
  justify-content: ${({ side }) => (side === 'left' ? 'flex-start' : 'flex-end')};
  align-items: flex-end;
`;

const Item = styled('button')<{
  disabled?: boolean;
  active?: boolean;
  color?: string;
}>`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  white-space: nowrap;
  flex-grow: 0;
  background: none;
  border: none;
  vertical-align: baseline;
  padding: 0 3px;
`;

const Marker = styled('span')<{ color?: string }>`
  position: relative;
  width: 14px;
  height: 2px;
  flex-shrink: 0;
  background-color: ${({ color, theme }) => color || theme.palette.text.primary};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    border: 2px solid ${({ color, theme }) => color || theme.palette.text.primary};
    border-radius: 50%;
    background-color: white;
  }
`;

const Label = styled(Typography)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 1px;

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.text.primary : theme.palette.text.secondary};
  }
`;

type LegendItemProps = {
  label: string;
  color?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LegendItem = ({ label, color, active, disabled, onClick }: LegendItemProps) => (
  <Item
    color={color}
    onClick={onClick}
    disabled={disabled}
    active={active}
    style={{
      opacity: active ? 1 : 0.4,
    }}
  >
    <Marker color={color} />
    <Label variant="caption" disabled={disabled}>
      {label}
    </Label>
  </Item>
);

function ChartLegend({
  series,
  hiddenSeries,
  setHiddenSeries,
  onChange,
  emptySeries,
}: ChartLegendProps) {
  const seriesLeft = series.filter((item) => item.yAxisIndex === 0);
  const seriesRight = series.filter((item) => item.yAxisIndex === 1);

  const handleLegendItemClick = (itemName: string, event: React.MouseEvent<HTMLButtonElement>) => {
    const seriesNames = series.map((s) => s.name);
    const currentVisibleSeries = seriesNames.filter(
      (seriesName) => !hiddenSeries.includes(seriesName),
    );

    let newVisibleSeries: Set<string>;

    if (isControl(event)) {
      newVisibleSeries = new Set(currentVisibleSeries);
      if (newVisibleSeries.has(itemName) && newVisibleSeries.size > 1) {
        newVisibleSeries.delete(itemName);
      } else {
        newVisibleSeries.add(itemName);
      }
    } else {
      newVisibleSeries = new Set(
        currentVisibleSeries.length === 1 && currentVisibleSeries.includes(itemName)
          ? seriesNames
          : [itemName],
      );
    }

    const updatedHiddenSeries = seriesNames.filter((name) => !newVisibleSeries.has(name));
    setHiddenSeries(updatedHiddenSeries);
    onChange?.(updatedHiddenSeries);
  };

  return (
    <Box>
      <Flex gap={3}>
        <Column side="left">
          {seriesLeft.map(({ name, label, color }) => (
            <LegendItem
              key={`legend-item-${name}`}
              onClick={(event) => handleLegendItemClick(name, event)}
              label={label}
              color={color}
              active={!hiddenSeries.includes(name)}
            />
          ))}
          {emptySeries.length ? (
            <Tooltip trigger={<IconInfo width={16} />} placement="right">
              <Typography variant="body2" fontWeight="bold" color="white">
                {dictionary.labelSeriesNoDataInfo}:
              </Typography>
              <Flex container gap={0.5} direction="column">
                {emptySeries.map((emptySeriesLabel) => (
                  <Typography variant="body2" color="white" key={`hidden-item-${emptySeriesLabel}`}>
                    - {emptySeriesLabel}
                  </Typography>
                ))}
              </Flex>
            </Tooltip>
          ) : null}
        </Column>

        {seriesRight.length > 0 && (
          <Column side="right">
            {seriesRight.map(({ name, label, color }) => (
              <LegendItem
                key={`legend-item-${name}`}
                onClick={(event) => handleLegendItemClick(name, event)}
                label={label}
                color={color}
                active={!hiddenSeries.includes(name)}
              />
            ))}
          </Column>
        )}
      </Flex>
    </Box>
  );
}

export default ChartLegend;
