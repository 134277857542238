import { useState } from 'react';
import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { ApiErrorPayload } from '@appTypes/api/error';
import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { GapY } from '@components/LayoutUtils';
import { RelationsTabs } from '@components/RelationsForm/RelationsTabs';
import { usePalette } from '@hooks/usePalette';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { dictionary } from '@settings/dictionary';
import { paths } from 'paths';
import { useParams } from 'react-router-dom';
import SiteList from './OrganizationForm/Relations/SiteList';
import { SiteRelationModal } from './OrganizationForm/Relations/SiteRelationModal';
import UserList from './OrganizationForm/Relations/UserList';
import { UserRelationModal } from './OrganizationForm/Relations/UserRelationModal';
import UpdateOrganizationForm from './OrganizationForm/UpdateOrganizationForm';

const UpdateOrganization = () => {
  const palette = usePalette();

  const params = useParams();
  const id = params.id as string;
  const organizationId = parseInt(id, 10);

  const tabs: ConfiguredItem[] = [ConfiguredItem.SITES, ConfiguredItem.USERS];

  const [organizationFormExpanded, setOrganizationFormExpanded] = useState(true);
  const [relationsTabVal, setRelationsTabVal] = useState(ConfiguredItem.SITES);
  const [siteRelationModalOpen, setSiteRelationModalOpen] = useState(false);
  const [userRelationModalOpen, setUserRelationModalOpen] = useState(false);

  const {
    data: organizationData,
    isLoading: isOrganizationLoading,
    error: organizationError,
  } = useGetOrganizationQuery(organizationId, {
    skip: !organizationId || !organizationFormExpanded,
  });

  const typedOrganizationError = organizationError as ApiErrorPayload | undefined;
  const dataErrorMessage = dictionary.errorCommon;
  const organizationErrorMessage =
    typedOrganizationError && typeof typedOrganizationError.data?.detail === 'string'
      ? typedOrganizationError.data.detail
      : dataErrorMessage;

  if (Number.isNaN(organizationId)) return null;

  return (
    <>
      <>
        <ButtonBack
          path={paths.configuration}
          query={{ selectedItem: ConfiguredItem.ORGANIZATIONS }}
        >
          {dictionary.buttonBackToConfigurationList}
        </ButtonBack>
        <GapY size={4} />
      </>

      <Grid container spacing={{ xs: 4, lg: 10 }} px={0} pt={0}>
        <Grid item xs={12} md={12} lg={12} xl={5} pt={0}>
          <Accordion
            expanded={organizationFormExpanded}
            onChange={() => setOrganizationFormExpanded(!organizationFormExpanded)}
            sx={{ background: palette.common.white }}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Organization</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {isOrganizationLoading && <CircularProgress />}
              {organizationError && !isOrganizationLoading ? (
                <Alert severity="error">{organizationErrorMessage}</Alert>
              ) : null}
              {organizationData && !isOrganizationLoading && !organizationError && (
                <UpdateOrganizationForm
                  organizationId={organizationId}
                  organizationData={organizationData}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={7}>
          <RelationsTabs value={relationsTabVal} tabs={tabs} onValueChange={setRelationsTabVal} />
          {relationsTabVal === ConfiguredItem.SITES && (
            <SiteList
              siteData={organizationData?.sites || []}
              onAddRelatedSite={() => setSiteRelationModalOpen(true)}
            />
          )}
          {relationsTabVal === ConfiguredItem.USERS && (
            <UserList
              userData={organizationData?.users || []}
              onAddRelatedUser={() => setUserRelationModalOpen(true)}
            />
          )}
        </Grid>
      </Grid>

      <SiteRelationModal open={siteRelationModalOpen} setOpen={setSiteRelationModalOpen} />
      <UserRelationModal open={userRelationModalOpen} setOpen={setUserRelationModalOpen} />
    </>
  );
};

export default UpdateOrganization;
