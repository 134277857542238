import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { UserDto } from '@appTypes/models/user.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { useRedirect, useFilters } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Box, Button } from '@mui/material';
import CONFIG from '@settings/config';
import { dictionary } from '@settings/dictionary';
import { ColumnDef } from '@tanstack/react-table';

type SitesGridCellProps = {
  id: number;
  name: string;
};

const CellWithRedirectButton = ({ id, name }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '220px',
      }}
    >
      <span>{name}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationSite(id))}>
        {dictionary.buttonSiteDetail}
      </Button>
    </Box>
  );
};

const columns: ColumnDef<SiteSimpleDto>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: '0 0 auto' }}>
        <CellWithRedirectButton name={row.original.name} id={row.original.id} />
      </div>
    ),
  },
];

interface SiteListProps {
  userData?: UserDto;
  onAddRelatedSite: () => void;
}

const SiteList = ({ userData, onAddRelatedSite }: SiteListProps) => {
  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<SiteSimpleDto>({
    data: userData?.sites || [],
    searchString,
    sorting,
    pagination,
    enableSorting: false,
    getSearchableString: (item) => item.name,
    getSortableValue: (item, sortId) => item[sortId as keyof SiteSimpleDto],
  });

  return (
    <>
      <Tooltip
        trigger={
          <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedSite} disabled={!userData?.is_active}>
            {dictionary.buttonAddRelatedSites}
          </ButtonAdd>
        }
        placement="top"
      >
        {!userData?.is_active ? dictionary.tooltipCannotAddSitesDeactivatedUser : ''}
      </Tooltip>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<SiteSimpleDto>
        columns={columns}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default SiteList;
