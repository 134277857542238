export const regex = {
  city: /^[^0-9!@#$%^&*_;()?~`"{}|<>[\]\\]*$/,
  country: /^[^0-9!@#$%^&*_;()?~`"{}|<>[\]\\]*$/,
  managerName: /^[^0-9<>;\\]*$/,
  organizationName: /^[^<>;\\]*$/,
  phoneNumber: /^[0-9+() -]*$/,
  postalCode: /^[A-Z0-9 -]*$/,
  siteName: /^[^<>;\\]*$/,
  street: /^[^<>;\\]*$/,
  userFirstName: /^[^<>;\\]*$/,
  userLastName: /^[^<>;\\]*$/,
};
