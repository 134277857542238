// TODO: Populate this dictionary with all commonly used error messages, titles, and other text strings across the application.
// This centralizes content management, making it easier to maintain consistency.
// Note: In the future, this dictionary can be replaced or extended with a more sophisticated translation solution.

export const dictionary = {
  // Button Labels
  buttonActivateUser: 'Activate user',
  buttonAddRelatedEcd: 'Add related ECD',
  buttonAddRelatedSites: 'Add related sites',
  buttonAddRelatedUser: 'Add related user',
  buttonBackToConfigurationList: 'Back to configuration list',
  buttonDeactivateUser: 'Deactivate user',
  buttonSiteDetail: 'Site detail',
  buttonUserDetail: 'User detail',
  buttonDownloadData: 'Download data',
  buttonCancel: 'Cancel',

  // ECD Permission Titles
  ecdPermissionView: 'View',
  ecdPermissionBasicEdit: 'Basic edit',
  ecdPermissionAdvancedEdit: 'Advanced edit',

  // Section Titles
  titleDataDownload: 'Data Download',
  titleTimePeriod: 'Time period',
  titleDataType: 'Data type',
  titleValueMultiplier: 'Value multiplier & data granularity',
  titleDataFormat: 'Data format',

  // Dialog Titles
  dialogTitleAddRelatedSite: 'Add related site',
  dialogTitleAddRelatedUser: 'Add related user',
  dialogTitleSetEcdPermission: 'Set ECD permission',

  // Error Messages
  errorAddEcdToSite: 'Some error occured while adding ECD to site',
  errorAddSiteToOrganization: 'Error adding site to organization',
  errorAddUserToOrganization: 'Error adding user to organization',
  errorAddUserToSite: 'Some error occured while adding user to site',
  errorAppBehindFirewall:
    'Podium application was not able to contact our servers, this can be caused by firewall, please try open Podium from another network',
  errorBookmarkLimitReached: (name: string, maximumCapacity: number) =>
    `Cannot bookmark site ${name}. Maximum capacity of ${maximumCapacity} sites reached`,
  errorCommon: 'Some unexpected error occured, please try again later',
  errorCommonAction:
    'We encountered an error while processing your request. Please try again later',
  errorGraphCommon: 'An issue occurred while retrieving graph data',
  errorGraphDrawing:
    'An error occurred while drawing the graph. If the problem persists, contact the application vendor',
  errorNoData: 'No data available. Please try again later',
  errorNoEcdFound: 'No ECD found for this site. Please contact support',
  errorNoUserSelected: 'No user selected',
  errorOffline: 'You are currently offline. Please check your internet connection',
  errorSiteAlreadyBookmarked: (name: string) => `Site ${name} is already bookmarked`,
  errorSiteNameNotBookmarked: (name: string) => `Site ${name} is not bookmarked`,
  errorSiteNotBookmarked: 'Site is not bookmarked.',
  errorTimePeriodTooShort: 'Time period must differ by at least one hour.',

  // Info Messages
  infoPreparingGraphData: 'Preparing graph data',
  infoSelectedSiteNotFound: 'Selected site was not found.',

  // Labels
  labelEcdDevicesPermissions: 'ECD devices permissions',
  labelSearchUser: 'Search user',
  labelSeriesNoDataInfo: 'No data available in this timeframe',
  labelNoUserSelected: 'No user selected',
  labelExportInfo: 'Select the time period, type, and granularity of the data you want to export',
  labelSelectDataFormat: 'Select data format',
  labelAllRawDataAvailable: 'All raw data available',
  labelGranularity10Minutes: '10 Minutes',
  labelGranularity15Minutes: '15 Minutes',
  labelGranularity30Minutes: '30 Minutes',
  labelGranularity1Hour: '1 Hour',
  labelGranularity24Hours: '24 Hours',
  labelTimeFrom: 'From',
  labelTimeTo: 'To',
  labelSelectMultiplier: 'Select value multiplier',
  labelSelectGranularity: 'Select granularity',
  labelSelectFormat: 'Select data format',

  // Table Headers
  headerUser: 'User',
  headerRole: 'Role',
  headerEcdPermissions: 'ECD Permissions',

  // Success Messages
  successOrganizationCreated: 'Organization successfully created',
  successOrganizationUpdated: 'Organization updated successfully',
  successSiteAdded: 'Site successfully added',
  successSiteBookmarked: (name: string) => `Site ${name} successfully bookmarked`,
  successSiteOptimizationUpdate: 'Site optimization successfully updated',
  successSiteUnbookmarked: (name: string) => `Site ${name} successfully unbookmarked`,
  successUserActivated: 'User activated successfully',
  successUserAdded: 'User successfully added',
  successUserDeactivated: 'User deactivated successfully',
  successUserUpdated: 'User updated successfully',
  successUserPermissionsSet: 'User permissions set successfully',

  // Tooltips
  tooltipCannotAddSitesDeactivatedUser: 'Cannot add sites for a deactivated user',
  tooltipCannotEditDeactivatedUser: 'Cannot edit a deactivated user',
  tooltipCannotEditEcdPermissionsDeactivatedUser:
    'Cannot edit ECD permissions for a deactivated user',
  tooltipDataExportInUtc: 'Data will be exported in UTC',

  // Validation Messages
  validationCityRequired: 'City is required',
  validationCityInvalidType: 'City must be a string',
  validationCityInvalidCharacters: 'City must not contain special characters.',
  validationCityMinLength: 'City must contain at least 2 characters',
  validationCityMaxLength: 'City must contain at most 50 characters',
  validationCountryRequired: 'Country is required',
  validationCountryInvalidType: 'Country must be a string',
  validationCountryInvalidCharacters: 'Country must not contain special characters',
  validationCountryMinLength: 'Country must contain at least 4 characters',
  validationCountryMaxLength: 'Country must contain at most 30 characters',
  validationEmailRequired: 'E-mail is required',
  validationEmailInvalidType: 'E-mail must be valid',
  validationFirstNameRequired: 'First Name is required',
  validationFirstNameInvalidType: 'First Name must be a string',
  validationFirstNameInvalidCharacters: 'First Name must not contain special characters',
  validationFirstNameMinLength: 'First Name must contain at least 2 characters',
  validationFirstNameMaxLength: 'First Name must contain at most 50 characters',
  validationLastNameRequired: 'Last Name is required',
  validationLastNameInvalidType: 'Last Name must be a string',
  validationLastNameInvalidCharacters: 'Last Name must not contain special characters',
  validationLastNameMinLength: 'Last Name must contain at least 2 characters',
  validationLastNameMaxLength: 'Last Name must contain at most 50 characters',
  validationManagerNameRequired: 'Manager Name is required',
  validationManagerNameInvalidType: 'Manager Name must be a string',
  validationManagerNameInvalidCharacters: 'Manager Name must not contain special characters',
  validationManagerNameMinLength: 'Manager Name must contain at least 4 characters',
  validationManagerNameMaxLength: 'Manager Name must contain at most 50 characters',
  validationOrganizationIdRequired: 'Organization is required',
  validationOrganizationIdInvalidType: 'Please select organization',
  validationOrganizationNameRequired: 'Organization Name is required',
  validationOrganizationNameInvalidType: 'Organization Name must be a string',
  validationOrganizationNameInvalidCharacters:
    'Organization Name must not contain special characters.',
  validationOrganizationNameMinLength: 'Organization Name must contain at least 2 characters',
  validationOrganizationNameMaxLength: 'Organization Name must contain at most 50 characters',
  validationPhoneRequired: 'Phone is required',
  validationPhoneInvalidType: 'Phone must be a string',
  validationPhoneInvalidCharacters: 'Phone must be valid',
  validationPhoneMinLength: 'Phone must contain at least 4 characters',
  validationPhoneMaxLength: 'Phone must contain at most 20 characters',
  validationPostalCodeRequired: 'Postal Code is required',
  validationPostalCodeInvalidType: 'Postal Code must be a string',
  validationPostalCodeInvalidCharacters: 'Postal Code must be valid',
  validationPostalCodeMinLength: 'Postal Code must contain at least 4 characters',
  validationPostalCodeMaxLength: 'Postal Code must contain at most 10 characters',
  validationRoleNameRequired: 'Role Name is required',
  validationRoleNameInvalidType: 'Role Name must be a valid UserRole',
  validationStreetRequired: 'Street is required',
  validationStreetInvalidType: 'Street must be a string',
  validationStreetInvalidCharacters: 'Street must not contain special characters.',
  validationStreetMinLength: 'Street must contain at least 2 characters',
  validationStreetMaxLength: 'Street must contain at most 50 characters',

  // Role Labels
  statusUserDeactivated: 'DEACTIVATED',
  roleManager: 'Manager',
  roleTechnician: 'Technician',
};

export type DictionaryKeys = keyof typeof dictionary;
