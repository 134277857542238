import { useEffect, useState } from 'react';
import { useUpdateOrganizationMutation } from '@apiRtk/organizations';
import { ApiErrorPayload } from '@appTypes/api/error';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { ButtonCancel, ButtonEdit, ButtonSave } from '@components/Buttons/Buttons';
import FormViewMode from '@components/FormViewMode/FormViewMode';
import { Flex, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentUserInfo } from '@hooks';
import { TextField, Typography, Box } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { dictionary } from '@settings/dictionary';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import organizationFormSchema from './OrganizationFormSchema';

const defaultValues = {
  name: '',
  manager_name: '',
  manager_email: '',
  manager_phone: '',
  street: '',
  city: '',
  postal_code: '',
  country: '',
};

type FormValues = z.infer<typeof organizationFormSchema>;

interface UpdateOrganizationFormProps {
  organizationId: number;
  organizationData?: OrganizationDto;
}

const UpdateOrganizationForm = ({
  organizationId,
  organizationData,
}: UpdateOrganizationFormProps) => {
  const { isAdmin, isSuperAdmin } = useCurrentUserInfo();

  const [updateOrganization, { isLoading }] = useUpdateOrganizationMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(organizationFormSchema),
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await updateOrganization({
        id: organizationId,
        ...data,
      }).unwrap();

      notificationService.success(dictionary.successOrganizationUpdated);
      setIsEditMode(false);
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : dictionary.errorCommonAction,
      );
    }
  };

  useEffect(() => {
    if (!organizationData) {
      return;
    }
    reset({
      name: organizationData.name || '',
      manager_name: organizationData.manager_name || '',
      manager_email: organizationData.manager_email || '',
      manager_phone: organizationData.manager_phone || '',
      street: organizationData.street || '',
      city: organizationData.city || '',
      postal_code: organizationData.postal_code || '',
      country: organizationData.country || '',
    });
  }, [organizationData, reset]);

  const fields = organizationData
    ? [
        { label: 'Organization Name', value: organizationData.name },
        { label: 'Manager Name', value: organizationData.manager_name },
        { label: 'Manager Email', value: organizationData.manager_email },
        { label: 'Manager Phone', value: organizationData.manager_phone },
        { label: 'Street', value: organizationData.street },
        { label: 'City', value: organizationData.city },
        { label: 'Postal Code', value: organizationData.postal_code },
        { label: 'Country', value: organizationData.country },
      ]
    : [];

  return (
    <Box>
      {isEditMode ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Organization Name"
                value={value}
                error={!!errors.name}
                fullWidth
                onChange={onChange}
              />
            )}
          />
          {errors.name && (
            <Typography variant="subtitle2" color="error">
              {errors.name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="manager_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Manager Name"
                value={value}
                error={!!errors.manager_name}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.manager_name && (
            <Typography variant="subtitle2" color="error">
              {errors.manager_name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="manager_email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Manager Email"
                value={value}
                error={!!errors.manager_email}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.manager_email && (
            <Typography variant="subtitle2" color="error">
              {errors.manager_email.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="manager_phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Manager Phone"
                value={value}
                error={!!errors.manager_phone}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.manager_phone && (
            <Typography variant="subtitle2" color="error">
              {errors.manager_phone.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="street"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Street"
                value={value}
                error={!!errors.street}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.street && (
            <Typography variant="subtitle2" color="error">
              {errors.street.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="City"
                value={value}
                error={!!errors.city}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.city && (
            <Typography variant="subtitle2" color="error">
              {errors.city.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="postal_code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Postal Code"
                value={value}
                error={!!errors.postal_code}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.postal_code && (
            <Typography variant="subtitle2" color="error">
              {errors.postal_code.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Country"
                value={value}
                error={!!errors.country}
                sx={{ width: '100%' }}
                onChange={onChange}
              />
            )}
          />
          {errors.country && (
            <Typography variant="subtitle2" color="error">
              {errors.country.message}
            </Typography>
          )}

          <GapY size={2} />

          <Flex gap={1}>
            <ButtonSave size="large" type="submit" disabled={isLoading} />
            <ButtonCancel onClick={() => setIsEditMode(false)} disabled={isLoading} />
          </Flex>
        </form>
      ) : (
        <Box>
          {organizationData && <FormViewMode fields={fields} />}
          {(isAdmin || isSuperAdmin) && (
            <Flex justifyContent="right">
              <ButtonEdit onClick={() => setIsEditMode(true)} />
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UpdateOrganizationForm;
