import { useGetOrganizationQuery } from '@apiRtk/organizations';
import { ProfilesData, useGetProfilesQuery } from '@apiRtk/users';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { CenterX } from '@components/LayoutUtils';
import RoleIcon from '@components/RoleIcon/RoleIcon';
import { Spinner } from '@components/Spinner';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { dictionary } from '@settings/dictionary';
import { Control, Controller } from 'react-hook-form';

const renderUsersSelectItem = (profile: ProfilesData[0]) => {
  const { fullname, email, role } = profile;

  return (
    <CenterX gap={1} justifyItems="space-between">
      <RoleIcon role={role} />
      <Box>
        <Typography>{fullname || '-'}</Typography>
        <Typography variant="body2" color="GrayText">
          {email}
        </Typography>
      </Box>
    </CenterX>
  );
};

type UserSelectProps = {
  organizationId: number;
  name: string;
  control: Control<any>;
  setValue: any;
  required?: boolean;
};

const UserSelectOrganization = ({
  organizationId,
  control,
  setValue,
  required,
  name,
}: UserSelectProps) => {
  const { data: profiles, isLoading } = useGetProfilesQuery({ excludeCurrentUser: false });
  const { data: organizationData, isLoading: isOrganizationLoading } =
    useGetOrganizationQuery(organizationId);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={dictionary.labelSearchUser} />
  );

  if (isLoading || isOrganizationLoading || !profiles || !organizationData) {
    return <Spinner size={15} />;
  }

  const getFilteredOptions = (users: ProfilesData, organization: OrganizationDto): ProfilesData => {
    const usersToFilter = organization.users;
    return users.filter(
      (user) => !usersToFilter.some((filteredUser) => filteredUser.id === user.id),
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={{ required }}
      render={({ field: { value, ...fieldProps } }) => (
        <Autocomplete
          {...fieldProps}
          options={getFilteredOptions(profiles, organizationData)}
          onChange={(_, val) => {
            setValue(name, val?.id || null, { shouldValidate: true });
          }}
          onBlur={() => {
            if (!value) {
              setValue(name, null, { shouldValidate: true });
            }
          }}
          value={profiles.find((profile) => profile.id === value) || null}
          getOptionLabel={(option) => `${option.fullname} (${option.email})`}
          renderInput={(params) => renderInput(params)}
          renderOption={(props, userData) => <li {...props}>{renderUsersSelectItem(userData)}</li>}
        />
      )}
    />
  );
};

export default UserSelectOrganization;
