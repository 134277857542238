import { useState } from 'react';
import { ArrayElement } from '@appTypes/helpers/arrayElement';
import { UserDto } from '@appTypes/models/user.dto';
import { RelationsGrid } from '@components/RelationsForm/RelationsGrid';
import { useRedirect } from '@hooks';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { getUsersSelector } from '@redux/ducks/users/selectors';
import { dictionary } from '@settings/dictionary';
import { useSelector } from 'react-redux';

type SitesGridCellProps = GridCellParams<string, ArrayElement<UserDto['sites']>>;

const CellWithRedirectButton = ({ row: { name, id } }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{`${name}`}</span>
      <Button
        variant="outlined"
        onClick={() => {
          redirect((paths) => paths.configurationSite(id));
        }}
      >
        {dictionary.buttonSiteDetail}
      </Button>
    </Box>
  );
};

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    renderCell: CellWithRedirectButton,
  },
];

export const SitesRelations = () => {
  const [searchVal, setSearchVal] = useState('');

  const {
    current: { data },
  } = useSelector(getUsersSelector);

  return (
    <RelationsGrid
      searchTxt={searchVal}
      setSearchTxt={setSearchVal}
      columns={columns}
      items={data?.sites || []}
      searchFields={['name']}
    />
  );
};
