import { FEATURE_FLAGS, FeatureFlagConfig } from '@appTypes/common';
import { ReactComponent as IconBattery } from '@icons/battery.svg';
import { ReactComponent as IconConsumption } from '@icons/factory.svg';
import { ReactComponent as IconEV } from '@icons/plug.svg';
import { ReactComponent as IconGrid } from '@icons/power.svg';
import { ReactComponent as IconRenewables } from '@icons/solar.svg';
import {
  DiagramConfig,
  ENERGY_FLOW_INDICATOR_COLORS,
} from '@pages/Sites/Overview/EnergyFlow/types';
import { icons } from '@pages/Sites/Overview/EnergyFlowOld/elements';
import { MILLIS_MINUTE, MILLIS_SECOND } from './constants';

export const featureFlags: FeatureFlagConfig[] = [
  {
    name: FEATURE_FLAGS.DASHBOARD_NEW_ENERGY_FLOW,
    label: 'New energy flow design',
    environments: ['development'],
  },
  {
    name: FEATURE_FLAGS.DISABLE_COMING_SOON_OVERLAYS,
    label: 'Disable coming soon overlays',
    environments: ['development'],
  },
  {
    name: FEATURE_FLAGS.ADVANCED_DATA_EXPORT,
    label: 'Advanced data export (only visual using mocked data)',
    environments: ['development'],
  },
];

export const AUTHENTICATION_FAILED_MESSAGE = 'Authentication failed';

export const MAX_BOOKMARKED_SITES = 10;

const sideAngles = {
  left: 180,
  right: 0,
  top: 270,
  bottom: 90,
};

const angleOffset = 20;

const DEVICES_CONFIG_OLD = {
  ev: {
    title: 'EV charging',
    icon: icons.ev,
  },
  grid: {
    title: 'Grid',
    energyColor: ENERGY_FLOW_INDICATOR_COLORS.red,
    icon: icons.grid,
    reverseFlow: true, // positive value = out flow
  },
  generation: {
    title: 'Renewables',
    energyColor: ENERGY_FLOW_INDICATOR_COLORS.green,
    icon: icons.solar,
    reverseFlow: true,
  },
  battery: {
    title: 'Battery storage',
    icon: icons.battery,
    iconOn: icons.batteryCharging,
    mainParameterName: 'charge_power',
  },
  consumption: {
    title: 'Consumption',
    icon: icons.consumption,
  },
};

const DEVICES_CONFIG: DiagramConfig = {
  ev: {
    title: 'EV charging',
    IconComponent: IconEV,
    angle: 0,
  },
  grid: {
    title: 'Grid',
    energyColor: ENERGY_FLOW_INDICATOR_COLORS.red,
    IconComponent: IconGrid,
    reverseFlow: true, // positive value = out flow
    angle: sideAngles.left + angleOffset,
  },
  generation: {
    title: 'Renewables',
    energyColor: ENERGY_FLOW_INDICATOR_COLORS.green,
    IconComponent: IconRenewables,
    reverseFlow: true,
    angle: sideAngles.right - angleOffset,
  },
  battery: {
    title: 'Battery storage',
    IconComponent: IconBattery,
    mainParameterName: 'charge_power',
    angle: sideAngles.right + angleOffset,
  },
  consumption: {
    title: 'Consumption',
    IconComponent: IconConsumption,
    angle: sideAngles.left - angleOffset,
  },
};

export const ENDPOINTS_WITHOUT_AUTH = [
  'login',
  'login/refresh',
  'login/logout',
  'login/send-verification-email',
  'login/verify-code',
  'login/set-password',
];

export const SENTRY_LOGGING = {
  ENABLED_PROD: true,
  ENABLED_DEV: false,
};

const CONFIG = {
  APP_VERSION: '1.1.3' /* Note: Update version before each release */,
  SUPPORT_EMAIL_ADDRESS: 'martin.marada@wattstor.com',
  ENERGY_FLOW: {
    DEVICES_CONFIG,
    DEVICES_CONFIG_OLD,
    DIAGRAM_REFRESH_INTERVAL: MILLIS_MINUTE,
    MAX_ENERGY_NODES: 5,
    MINIMAL_VALID_VALUE: 1000,
  },
  ENDPOINTS: {
    CACHE_DURATION: {
      // Note: Cache duration in seconds
      ALARMS: 30,
    },
    ITEMS_PER_PAGE: 10,
    ITEMS_PER_PAGE_SMALL: 5,
    ALL_ITEMS_PAGESIZE: 999999, // Workaround for pagesize, when we need to fetch all items at once
  },
  GRAPHS: {
    YAXIS_OFFSET_X: 60,
    DATA_LIMIT: 1000,
    LAZY_LOAD_THRESHOLD_INDEX: 8,
  },
  CARDS: {
    COMING_SOON_LABELS: true,
    GRAPHS_DATAPOINTS_COUNT: 15,
  },
  SEARCH_TEXT_MAX_LENGTH: 30,
  ALERT_AUTO_HIDE_DURATION_MS: MILLIS_SECOND * 5,
  REFRESH_TOKEN_CALL_TIMEOUT: MILLIS_SECOND * 10,
  HEAVY_LIST_TEST_TIMEOUT: MILLIS_SECOND * 15,
};

export default CONFIG;
