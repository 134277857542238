import { useState } from 'react';
import { FEATURE_FLAGS } from '@appTypes/common';
import { AppLink } from '@components/AppLink';
import { Download } from '@mui/icons-material';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import DataExportPanelAdvanced from './DataEportPanelAdvanced';
import DataExportPanel from './DataExportPanel';
import DataExportSuccessMessage from './DataExportSuccessMessage';

const DataExport = () => {
  const [open, setOpen] = useState(false);
  const [exportSuccessOpen, setExportSuccessOpen] = useState(false);

  return (
    <>
      <AppLink
        onClick={() => {
          setOpen(true);
        }}
        icon={<Download />}
      >
        Download data
      </AppLink>
      {isFeatureFlagEnabled(FEATURE_FLAGS.ADVANCED_DATA_EXPORT) ? (
        <DataExportPanelAdvanced
          open={open}
          setOpen={setOpen}
          onSuccessMessageOpen={setExportSuccessOpen}
        />
      ) : (
        <DataExportPanel
          open={open}
          setOpen={setOpen}
          onSuccessMessageOpen={setExportSuccessOpen}
        />
      )}
      <DataExportSuccessMessage open={exportSuccessOpen} setOpen={setExportSuccessOpen} />
    </>
  );
};

export default DataExport;
